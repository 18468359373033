<template>
    <div class="block-color-wrapper" ref="bcw">
        <div class="left-table" @click="prevPage" v-show="tableData.length > 3">
            <span class="iconfont iconjiantouzuo"></span>
        </div>
        <div class="right-table" @click="nextPage" v-show="tableData.length > 3">
            <span class="iconfont iconjiantouyou"></span>
        </div>
        <div class="color-header">
            <div class="selectTime">
                <p class="iconfont iconshijian1">
                    时间：
                </p>
                <span>{{ currentTime }}</span>
                <!-- <strong>00:00~24:00</strong> -->
                <button @click="prveDay">
                    上一天
                </button>
                <button @click="sameDay">
                    当天
                </button>
                <button @click="nextDay">
                    下一天
                </button>
            </div>
            <div class="total">
                <strong>每格产能 =<i>{{ capacity }}</i>方, 当前已排产<i style="font-size:0.3rem;color:blue">{{ selectSquareQuantity }}</i>方。</strong>
                <!-- <strong>当前已选<i style="font-size:0.3rem;color:blue">{{ selectSquareQuantity }}</i>方</strong> -->
            </div>
            <div class="tips">
                <strong><i class="el-icon-s-opportunity"></i>提示：按住shift多选</strong>
            </div>
        </div>
        <div class="color-header">
            <div class="select-text">
                <p class="iconfont iconyanse">
                    选择颜色：
                </p>
            </div>
            <div class="clean-btn" :class="{'checkout-clean': isClean}" @click="cleanColorBlock">
                <p>清除</p>
            </div>
            <div class="color-block-list">
                <span
                    v-for="(item, index) in selectColorData"
                    :key="index"
                    :style="item"
                    class="iconfont iconqueding"
                    :class="{'active': colorIndex === index}"
                    @click="selectColor(index, item.backgroundColor)"
                ></span>
            </div>
            <div class="btn-wrapper">
                <button class="btn determine" @click="addColorBlock">
                    确定
                </button>
                <button class="btn reset" @click="resetColorBlock">
                    重置
                </button>
            </div>
            <div class="completed">
                <span></span>
                <p>已完成</p>
            </div>
        </div>
        <div class="color-table">
            <div
                class="table-wrapper"
                :style="{
                    width:`${(3.12*tableData.length)+(tableData.length*0.2)}rem`,
                    position:'absolute',
                    top:0,left:`-${3.22*pageIndex}rem`,
                    transition:'all 1s'}"
                @mousedown="tableMousedown($event)"
                @mousemove.stop="tableMousemove($event)"
                @mouseup.stop="tableMouseup($event)"
                @mouseleave="tableMouseleave($event)"
                ref="tw"
            >
                <div
                    id="moveSelected"
                    :style="{top:moveSelectedTop, left: moveSelectedLeft, width: moveSelectedWidth, height:moveSelectedHeight}"
                ></div>
                <div
                    v-for="(item,index) in tableData"
                    :key="index"
                    :class="`table-item table_item_${index}`"
                >
                    <!-- :style="{transform:`translate(${100*index+index*6}%,0 -->
                    <table>
                        <tbody>
                            <tr>
                                <td :colspan="item.planData.length">
                                    <strong style="color:#5588F1">{{ item.line_name }}</strong>
                                </td>
                            </tr>
                            <tr>
                                <template v-for="(jtem,jndex) in item.planData">
                                    <td :key="jndex">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>
                                                        {{ jtem.label }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(ktem, kndex) in jtem.data">
                                                    <tr :key="kndex">
                                                        <td>
                                                            <span
                                                                :style="{backgroundColor: ktem.block_color}"
                                                                @click="signPlan(ktem,index,jndex,kndex)"
                                                                :lineIndex="index"
                                                                :timeIndex="jndex"
                                                                :index="kndex"
                                                                :class="{'rspan': ktem.task_number === taskNumber,'bg': ktem.block_flag===1}"
                                                            >{{ ktem.task_number.substring(13,17) }}</span>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="color-footer">
            <span
                class="item"
                v-for="(item,index) in 24"
                :key="index"
                @click="timeLineClick(item)"
                :class="{'item-active': item<= sliderIndex && item > sliderIndex-4}"
            >
                {{ item }}
            </span>
            <div class="slider" :style="{left:`${4.167*(sliderIndex-4)}%`,transition:'all 0.2s'}">
                <span
                    class="slider-btn prev_slider"
                    @click="prevSlider"
                >
                    往后移
                </span>
                <span
                    class="slider-btn next_slider"
                    @click="nextSlider"
                >
                    往前移
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import planData from '../../../../public/planData2.json';
import _ from 'loadsh';
export default {
    props: {
        mixstationlineData: { // 线路数组
            type: Array,
        },
        station: { // 场站code
            type: String,
        },
        taskNumber: {
            type: String, // 任务编号
        },
        blockCzColor: { // 任务颜色块
            type: String,
        },
        taskItem: { // 任务颜色块
            type: [String, Object],
        },
    },
    data() {
        return {
            // 选择颜色数据集合
            selectColorData: [
                // {
                //     backgroundColor: '#979EA7',
                //     borderColor: '#D7D7D7',
                // },
                {
                    backgroundColor: '#5588F1',
                    borderColor: '#2F5DBA',
                },
                {
                    backgroundColor: '#EB656F',
                    borderColor: '#B24048',
                },
                {
                    backgroundColor: '#21BDCC',
                    borderColor: '#158D99',
                },
                {
                    backgroundColor: '#FA8436',
                    borderColor: '#C26223',
                },
                {
                    backgroundColor: '#8B6BEE',
                    borderColor: '#6145B8',
                },
            ],
            // 选择色块的索引
            colorIndex: null,
            // 选中色块的背景颜色
            currentColor: '',
            // 滑块默认位置 默认四格
            sliderIndex: 4,
            // 时间范围 [1,2,3,5]
            timeArr: [],
            // 生成的时间范围 [0.5,1,1.5,2,...,4]
            timeSectionArray: [],
            // 选择时间的区间的表格数据
            currentPlanData: [],
            PlanDataAll: [],
            tableData: [],
            // 鼠标拖拽出的遮罩元素位置-top
            moveSelectedTop: '',
            // 鼠标拖拽出的遮罩元素位置-left
            moveSelectedLeft: '',
            // 鼠标拖拽出的遮罩元素位置-right
            moveSelectedRight: '',
            // 鼠标拖拽出的遮罩元素位置-bottom
            moveSelectedBottom: '',
            // 鼠标拖拽出的遮罩元素宽
            moveSelectedWidth: '',
            // 鼠标拖拽出的遮罩元素高
            moveSelectedHeight: '',
            // 是否开启拖拽的标志
            flag: false,
            // 鼠标按下时的left
            oldLeft: 0,
            // 鼠标按下时的top
            oldTop: 0,
            // 拖拽，选中的块数据集合
            selectedList: [],
            // 当前选择方量
            selectSquareQuantity: 0,
            // 要删除的块集合
            delectList: [],
            // 当前任务已保存块，重置用
            currentTaskPreservation: [],
            // 默认第几页
            pageIndex: 0,
            // 当前日期
            currentTime: this.getDateStr(0),
            // 选择日期变量
            day: 0,
            // 是否是清除状态
            isClean: false,
            total: 0, // 总产能值
            select: 0, // 添加的色块产能值
            delete: 0, // 删除的色块产能值
            capacity: 0, // 每个色块的产能值
        };
    },
    created() {
        let capacity = 0;
        this.PlanDataAll = planData.DataContext;
        if (this.mixstationlineData.length > 0) {
            capacity = Number(this.mixstationlineData[0].capacity / 20);
        }
        this.capacity = capacity;
    },
    mounted() {
        this.initData();
        this.getStationColorBlocks();
        // 初始化默认表格结
        this.initBlockCzColor();
    },
    methods: {
        // 选择颜色块
        selectColor(index, currentColor) {
            if (this.taskItem.block_cz_color) {
                this.$message.error('已有选择的颜色值');
            } else {
                this.colorIndex = index;
                this.currentColor = currentColor;
            }
            this.isClean = false;
        },
        // 根据生产任务选中对应的颜色值
        initBlockCzColor(val) {
            const that = this;
            if (val) {
                if ( val.block_cz_color) {
                    this.currentColor = val.block_cz_color;
                    that.selectColorData.map((item, index) => {
                        if (item.backgroundColor === val.block_cz_color) {
                            that.colorIndex = index;
                        }
                    });
                } else {
                    that.currentColor = '';
                    that.colorIndex = null;
                }
            } else if ( that.taskItem.block_cz_color) {
                this.currentColor = that.taskItem.block_cz_color;
                that.selectColorData.map((item, index) => {
                    if (item.backgroundColor === that.taskItem.block_cz_color) {
                        that.colorIndex = index;
                    }
                });
            } else {
                that.currentColor = '';
                that.colorIndex = null;
            }
        },
        // 设置任务单块颜色
        settaskNumberColorBlock() {
            this.$axios.put('/interfaceApi/production/producttask/block_cz_color', {
                task_number: this.taskNumber,
                block_color: this.currentColor,
            })
                .then( () => {
                    this.$message.success('设置任务单颜色块成功');
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 添加块
        addColorBlock() {
            if (this.currentColor) {
                this.$axios.post('/interfaceApi/production/producttask/add_cz_blocks', {
                    adds: this.selectedList,
                    deletes: this.delectList,
                })
                    .then( () => {
                        this.$message.success('保存任务单颜色块成功');
                        this.initData();
                        // 获取默认场站已选择块
                        this.getStationColorBlocks();
                        this.selectedList = [];
                        this.delectList = [];
                        if (!this.taskItem.block_cz_color ) {
                            this.settaskNumberColorBlock();
                        }
                        this.$parent.shipments(this.$parent.timeSlot[0], this.$parent.timeSlot[1]);
                        this.isClean = false;
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            } else {
                this.$message.error('请选择颜色块');
            }

        },
        // 重置块
        resetColorBlock() {
            this.$axios.post('/interfaceApi/production/producttask/reset_cz_blocks', {
                task_number: this.taskNumber,
                product_date: this.currentTime,
                station: this.station,
            })
                .then( () => {
                    this.$message.success('重置成功');
                    this.$parent.shipments(this.$parent.timeSlot[0], this.$parent.timeSlot[1]);
                    this.initData();
                    // 获取默认场站已选择块
                    this.getStationColorBlocks();
                    // this.initBlockCzColor();
                    this.currentColor = '';
                    this.colorIndex = null;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 获取场站下所有任务的颜色块
        getStationColorBlocks() {
            this.$axios.get('/interfaceApi/production/producttask/czblocks?plan_date='
            + this.currentTime + '&station=' + this.station + '&start_timesq='
            + (this.sliderIndex - 3) + '&end_timesq=' + this.sliderIndex )
                .then( res => {
                    if (res.length > 0) {
                        this.tableData.forEach(i => {
                            res.forEach(j => {
                                if (i.line_code === j.line) {
                                    i.planData.forEach(v => {
                                        v.data.forEach(k => {
                                            if (k.block_timesq === j.block_timesq && k.block_position === j.block_position) {
                                                k.task_number = j.task_number;
                                                k.product_date = j.product_date;
                                                k.station = j.station;
                                                k.line = j.line;
                                                k.block_color = j.block_color;
                                                k.block_capacity = j.block_capacity;
                                                k.block_timesq = j.block_timesq;
                                                k.block_position = j.block_position;
                                                k.block_sq = j.block_sq;
                                                k.block_time = j.block_time;
                                                k.block_flag = j.block_flag;
                                                k.sort_num = j.sort_num;
                                            }
                                        });
                                    });
                                }
                            });
                        });
                    } else {
                        this.initData();
                    }
                    this.currentTaskPreservation = res;
                    this.totalNubmer();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        totalNubmer() {
            this.selectSquareQuantity = 0;
            if (this.currentTaskPreservation.length > 0) {
                this.total = 0;
                this.currentTaskPreservation.forEach(item => {
                    this.total += item.block_capacity;
                });
            } else {
                this.total = 0;
            }

            if (this.selectedList.length > 0) {
                this.select = 0;
                this.selectedList.forEach(item => {
                    this.select += Number(item.block_capacity);
                });
            } else {
                this.select = 0;
            }

            if (this.delectList.length > 0) {
                this.delete = 0;
                this.delectList.forEach(item => {
                    this.delete += Number(item.block_capacity);
                });
            } else {
                this.delete = 0;
            }

            this.selectSquareQuantity = this.total + this.select - this.delete;
        },
        // 时间处理
        getDateStr(dayCount) {
            if (null === dayCount) {
                dayCount = 0;
            }
            const dd = new Date();
            dd.setDate(dd.getDate() + dayCount);
            const y = dd.getFullYear();
            let m = dd.getMonth() + 1;
            let d = dd.getDate();
            if (m < 10) {
                m = '0' + m;
            }
            if (d < 10) {
                d = '0' + d;
            }
            return y + '-' + m + '-' + d;
        },
        sameDay() {
            this.day = 0;
            this.currentTime = this.getDateStr(0);
            this.initData();
            // 获取默认场站已选择块
            this.getStationColorBlocks();
        },
        prveDay() {
            this.day--;
            this.currentTime = this.getDateStr(this.day);
            this.initData();
            // 获取默认场站已选择块
            this.getStationColorBlocks();
        },
        nextDay() {
            this.day++;
            this.currentTime = this.getDateStr(this.day);
            this.initData();
            // 获取默认场站已选择块
            this.getStationColorBlocks();
        },
        // 初始化表格结构
        initData() {
            const tableArr = [];
            this.mixstationlineData.forEach(item => {
                tableArr.push({
                    line_name: item.line_name,
                    line_code: item.line_code,
                    capacity: item.capacity,
                    planData: [],
                });
            });

            // 根据时间范围生成表格闲X轴范围
            this.timeArr = [this.sliderIndex - 4, this.sliderIndex];
            const tarr = [];
            const parr = [];
            for (let i = 1; i <= 8; i++) {
                tarr.push(this.timeArr[0] + i * 0.5);
                this.timeSectionArray = JSON.parse(JSON.stringify(tarr));
            }
            this.PlanDataAll.forEach(jtem => {
                this.timeSectionArray.forEach(item => {
                    if ( jtem.label === String(item)) {
                        parr.push(jtem);
                        this.currentPlanData = JSON.parse(JSON.stringify(parr));
                    }
                });
            });
            tableArr.forEach(item => {
                item.planData = this.currentPlanData;
            });
            this.tableData = JSON.parse(JSON.stringify(tableArr));

        },
        // 下一页
        nextPage() {
            if (this.pageIndex >= this.tableData.length - 3) {
                this.pageIndex = this.tableData.length - 3;
                return;
            }
            this.pageIndex++;
        },
        prevPage() {
            if (this.pageIndex === 0) {
                this.pageIndex = 0;
                return;
            }
            this.pageIndex--;
        },
        tableMousedown(event) {
            // 判断键盘shift是否被按下
            if (event.shiftKey) {
                this.flag = true;
                // 鼠标拖拽出的遮罩元素位置-top
                this.moveSelectedTop = event.pageY + 'px';
                // 鼠标拖拽出的遮罩元素位置-left
                this.moveSelectedLeft = event.pageX + 'px';
                // 鼠标按下时的left
                this.oldLeft = event.pageX;
                // 鼠标按下时的left
                this.oldTop = event.pageY;
            }
        },
        // 获取计划调度表格鼠标移动时计算遮罩的位置，宽 高
        tableMousemove(event) {
            if (!this.flag) {return;} // 只有开启了拖拽，才进行mouseover操作
            if (event.pageX < this.oldLeft) { // 向左拖
                this.moveSelectedLeft = event.pageX + 'px';
                this.moveSelectedWidth = this.oldLeft - event.pageX + 'px';
            } else {
                this.moveSelectedWidth = event.pageX - this.oldLeft + 'px';
            }
            if (event.pageY < this.oldTop) { // 向上
                this.moveSelectedTop = event.pageY + 'px';
                this.moveSelectedHeight = this.oldTop - event.pageY + 'px';
            } else {
                this.moveSelectedHeight = event.pageY - this.oldTop + 'px';
            }
        },
        // 获取计划调度表格鼠标抬起时计算遮罩的right 和 bottom，找出遮罩覆盖的块，关闭拖拽选中开关，清除遮罩数据
        tableMouseup() {
            if (!this.flag) {return;} // 只有开启了拖拽，才进行操作
            this.moveSelectedBottom = Number(this.moveSelectedTop.split('px')[0]) + Number(this.moveSelectedHeight.split('px')[0]);
            this.moveSelectedRight = Number(this.moveSelectedLeft.split('px')[0]) + Number(this.moveSelectedWidth.split('px')[0]);
            this.findSelected();
            this.flag = false;
            this.tableMouseleave();
        },

        // 获取计划调度表格,计算选中区域
        findSelected() {
            const _that = this;
            if (this.currentColor === '' || this.colorIndex === null) {
                _that.$message.error('请先选择颜色');
                return;
            }

            const blockList = $('.table-wrapper').find('span');
            for (let i = 0; i < blockList.length; i++) {
                // 计算每个块的定位信息
                const left = $(blockList[i]).offset().left;
                const right = $(blockList[i]).width() + left;
                const top = $(blockList[i]).offset().top;
                const bottom = $(blockList[i]).height() + top;

                // 判断每个块是否被遮罩盖住（即选中）
                const leftFlag = _that.moveSelectedLeft.split('px')[0] <= left && left <= _that.moveSelectedRight;
                const rightFlag = _that.moveSelectedLeft.split('px')[0] <= right && right <= _that.moveSelectedRight;
                const topFlag = _that.moveSelectedTop.split('px')[0] <= top && top <= _that.moveSelectedBottom;
                const bottomFlag = _that.moveSelectedTop.split('px')[0] <= bottom && bottom <= _that.moveSelectedBottom;

                if ((leftFlag || rightFlag) && (topFlag || bottomFlag)) {
                    // 获取表格横向下标
                    const timeIndex = $(blockList[i]).attr('timeIndex');
                    // 获取表格纵向下标
                    const Index = $(blockList[i]).attr('index');
                    // 当前线路坐标
                    const LineIndex = $(blockList[i]).attr('lineIndex');

                    if (!this.isClean) {
                        if ( _that.tableData[LineIndex].planData[timeIndex].data[Index].block_color === '') {
                            // 给数据里对应位置赋值block_color
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].block_color = _that.currentColor;
                            // 给数据里对应位置赋值block_capacity
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].block_capacity = _that.tableData[LineIndex].capacity / 20;
                            // 给数据里对应位置赋值task_number
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].task_number = this.taskNumber;
                            // 给数据里对应位置赋值product_date
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].product_date = this.currentTime;
                            // 给数据里对应位置赋值station
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].station = this.station;
                            // 给数据里对应位置赋值line_code
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].line = _that.tableData[LineIndex].line_code;
                            // 给数据里对应位置赋值block_flag
                            _that.tableData[LineIndex].planData[timeIndex].data[Index].block_flag = 0;
                            // 将选择的色块存储到添加数组中去
                            _that.selectedList.push(_that.tableData[LineIndex].planData[timeIndex].data[Index]);
                            // 多选去重
                            _that.selectedList = Array.from(new Set(_that.selectedList));
                            // 当前任务选择方量
                            // _that.selectSquareQuantity = '';
                            this.totalNubmer();
                        }
                    } else {
                        // 多选清除
                        const data = this.tableData[LineIndex].planData[timeIndex].data[Index];
                        this.selectClean(data);
                    }
                }
            }
        },
        selectClean(data) {
            if (data.block_color !== '') {
                if (data.block_time) {
                    if (data.task_number === this.taskNumber) {
                        data.block_color = '';
                        this.delectList.push(data);
                        // 多选去重
                        this.delectList = Array.from(new Set(this.delectList));
                        this.totalNubmer();
                    } else {
                        this.$message.error('不能删除其他任务的排产');
                    }

                } else {
                    data.block_color = '';
                    this.selectedList = _.remove(this.selectedList, function (n) {
                        return n.block_color !== '';
                    });
                    this.totalNubmer();
                }
            }
            return;
        },
        // 鼠标移出计划调度表格，遮罩层数据复原
        tableMouseleave() {
            // 鼠标拖拽出的遮罩元素位置-top
            this.moveSelectedTop = '';
            // 鼠标拖拽出的遮罩元素位置-left
            this.moveSelectedLeft = '';
            // 鼠标拖拽出的遮罩元素位置-right
            this.moveSelectedRight = '';
            // 鼠标拖拽出的遮罩元素位置-bottom
            this.moveSelectedBottom = '';
            // 鼠标拖拽出的遮罩元素宽
            this.moveSelectedWidth = '';
            // 鼠标拖拽出的遮罩元素高
            this.moveSelectedHeight = '';
            // 是否开启拖拽的标志
            this.flag = false;
        },
        // eslint-disable-next-line max-params
        signPlan(data, lineIndex) {
            const that = this;
            if (this.currentColor && !this.isClean) {
                if (data.block_color === '') {
                    // 给数据里对应位置赋值block_color
                    data.block_color = that.currentColor;
                    // 给数据里对应位置赋值block_capacity
                    data.block_capacity = that.tableData[lineIndex].capacity / 20;
                    // 给数据里对应位置赋值task_number
                    data.task_number = this.taskNumber;
                    // 给数据里对应位置赋值product_date
                    data.product_date = this.currentTime;
                    // 给数据里对应位置赋值station
                    data.station = this.station;
                    // 给数据里对应位置赋值line_code
                    data.line = that.tableData[lineIndex].line_code;
                    // 给数据里对应位置赋值block_flag
                    data.block_flag = 0;
                    // 将选择的色块存储到添加数组中去
                    this.selectedList.push(data);

                    // 多选去重
                    this.selectedList = Array.from(new Set(this.selectedList));
                    // 当前任务选择方量
                    this.totalNubmer();
                } else if (data.task_number !== this.taskNumber) {
                    this.$message.error('已被其他任务排产');
                }
            } else if (this.currentColor && this.isClean) {
                if (data.block_color !== '') {
                    if (data.block_time) {
                        if (data.task_number === this.taskNumber) {
                            data.block_color = '';
                            this.delectList.push(data);
                            // 多选去重
                            this.delectList = Array.from(new Set(this.delectList));
                            this.totalNubmer();
                        } else {
                            this.$message.error('不能删除其他任务的排产');
                        }

                    } else {
                        data.block_color = '';
                        this.selectedList = _.remove(this.selectedList, function (n) {
                            return n.block_color !== '';
                        });
                        this.totalNubmer();
                    }
                }
                return;
            } else {
                this.$message.error('请选择颜色');
            }
        },
        removeColorBlock() {

        },
        cleanColorBlock() {
            // this.colorIndex = null;
            // this.currentColor = '';
            this.isClean = true;
        },
        // 修改时间范围
        timeLineClick(item) {
            const sliderIndex = this.sliderIndex;
            if (item > sliderIndex) {
                this.sliderIndex = item;
            }

            if (item < sliderIndex) {
                this.sliderIndex = item + 3;
            }
            this.initData();
            this.getStationColorBlocks();
            this.selectedList = [];
            this.delectList = [];
            this.totalNubmer();

        },
        // 往前移
        prevSlider() {
            if (this.sliderIndex === 4) {
                this.sliderIndex = 4;
                return;
            }
            this.sliderIndex--;
            this.initData();
            this.getStationColorBlocks();
            this.selectedList = [];
            this.delectList = [];
            this.totalNubmer();
        },
        // 往后移
        nextSlider() {
            if (this.sliderIndex === 24) {
                this.sliderIndex = 24;
                return;
            }
            this.sliderIndex++;

            this.initData();
            this.getStationColorBlocks();
            this.selectedList = [];
            this.delectList = [];
            this.totalNubmer();
        },
    },
};
</script>

<style lang="stylus" scope>
    body
        -moz-user-select none
        -webkit-user-select none
        user-select none
    .block-color-wrapper
        width 100%
        height 4rem
        background rgba(255,255,255,1)
        box-shadow 0px -2px 8px 0px rgba(85,136,241, 0.35)
        position absolute
        left -100%
        bottom 0
        z-index 99
        padding 0.1rem 0.2rem
        display flex
        -webkit-display flex
        -ms-display flex
        flex-direction column
        .left-table
            position absolute
            width 0.2rem
            height 2.25rem
            top 0.88rem
            left 0
            z-index 9999
            line-height 100%
            line-height 2.45rem
            cursor pointer
            &:hover
                background rgba(0,0,0,1)
                opacity 0.3
                color #fff
        .right-table
            position absolute
            width 0.2rem
            height 2.25rem
            top 0.88rem
            right 0
            z-index 9999
            line-height 100%
            line-height 2.45rem
            cursor pointer
            &:hover
                background rgba(0,0,0,1)
                opacity 0.3
                color #fff
        .color-header
            display flex
            -webkit-display flex
            -ms-display flex
            height 0.3rem
            margin-bottom 0.04 rem
            .select-text
                color #979EA7
                height 0.30rem
                p
                    font-size 0.14rem
                    line-height 0.30rem
            .clean-btn
                width 0.6rem
                height 0.30rem
                border 0.01rem solid rgba(215,215,215,1)
                &.checkout-clean
                    border 0.01rem solid rgba(21,119,210,1)
                    p
                        color rgba(21,119,210,1)
                p
                    font-size 0.14rem
                    background url(../images/eraser.png) left center no-repeat
                    background-size auto 60%
                    line-height 0.30rem
                    text-align center
                    padding-left 0.2rem
                    cursor pointer
            .color-block-list
                height 0.30rem
                span
                    width 0.24rem
                    height 0.16rem
                    display inline-block
                    margin-top 0.07rem
                    margin-left 0.1rem
                    cursor pointer
                    line-height 0.16rem
                    text-align center
                    color transparent
                    &.active
                        border 0.01rem solid #d7d7d7;
                        color #ffffff
                        box-sizing border-box
            .btn-wrapper
                height 0.30rem
                .btn
                    width 0.4rem
                    height 0.26rem
                    font-size 0.14rem
                    border-radius: 2px;
                    border none
                    margin-left 0.1rem
                    cursor pointer
                    &.determine
                        background #1577D2
                        color #fff
                    &.reset
                        color #1577D2
                        border 0.01rem solid #1577D2
            .completed
                height 0.30rem
                margin-left 0.2rem
                span
                    width 0.24rem
                    height 0.17rem
                    display inline-block
                    background url(../images/check_bg.png) no-repeat
                    background-size 100% 100%
                    // border 0.01rem solid rgba(151, 158, 167, 1)
                    margin-top 0.07rem
                    float left
                p
                    line-height 0.30rem
                    font-size 0.14rem
                    color #666666
                    float left
                    padding-left 0.1rem
            .total
                height 0.3rem
                line-height 0.3rem
                font-size 0.14rem
                margin-left 0.1rem
            .tips
                height 0.30rem
                background rgba(254,238,238,1)
                color #EE3737
                font-size 0.14rem
                margin-left 0.1rem
                line-height 0.30rem
                padding 0 0.06rem
            .selectTime
                p
                    font-size 0.16rem
                    color #979EA7
                    line-height 0.3rem
                    float left
                span,strong
                    font-size 0.16rem
                    line-height 0.3rem
                    color #333
                    font-weight 400
                    margin-right 0.1rem
                button
                    width 0.6rem
                    height 0.26rem
                    border none
                    margin-left 0.1rem
                    cursor pointer
                    font-size 0.16rem
                    &:nth-of-type(1),
                    &:nth-of-type(3)
                        background rgba(21, 119, 210, 0.1)
                        color rgba(21, 119, 210, 1)
                    &:nth-of-type(2)
                        background rgba(235, 101, 111, 0.1)
                        color rgba(235, 101, 111, 1)
        .color-table
            flex 1
            padding 0.1rem 0
            overflow hidden
            width 100%
            position relative
            .table-wrapper
                #moveSelected
                    position fixed
                    background-color blue
                    opacity 0.3
                    border 1px dashed #d9d9d9
                    top 0
                    left 0
                .table-item
                    width 3.12rem
                    float left
                    &:nth-child(odd)
                        margin 0 0.1rem
                    table
                        width 100%
                        height 100%
                        border-collapse collapse
                        table-layout fixed
                        word-break break-all
                        th,td
                            text-align center
                            border 1px solid #D7D7D7
                            font-size 10px
                        table
                            th,td
                                height 0.18rem
                                border none
                            th
                                width calc(100%/8)
                                color #333333
                                font-weight 400
                            td
                                border-top 1px solid #D7D7D7
                                span
                                    display block
                                    width 100%
                                    height 100%
                                    background #F0F0F0
                                    cursor pointer
                                    &.rspan
                                        color #ffffff
                                    &.bg
                                        background url(../images/check_bg.png) no-repeat
                                        background-size 100% 100%
        .color-footer
            height 0.6rem
            display flex
            position relative
            .item
                height 0.2rem
                display inline-block
                flex 1
                background #D7D7D7
                font-size 0.14rem
                text-align center
                line-height 0.2rem
                border-right 0.01rem solid #fff
                margin-top 0.3rem
                box-sizing border-box
                cursor pointer
                &:last-child
                   border-right none
                &.item-active
                    background #5588F1
                    color #ffffff
            .slider
                position absolute
                height 0.6rem
                width 16.67%
                border 0.01rem solid #cccccc
                top 0
                left 0
                display flex
                justify-content space-between
                .slider-btn
                    font-size 0.10rem
                    height 0.2rem
                    width 0.6rem
                    margin-top 0.05rem
                    cursor pointer
                    color #1577D2
                    text-align center
                    line-height 0.2rem
                    &.prev_slider
                        background url(../images/arrow2.png) no-repeat
                        background-size 100% 100%
                        &:hover
                            background url(../images/arrow1.png) no-repeat
                            background-size 100% 100%
                            color #ffffff
                    &.next_slider
                        background url(../images/arrow4.png) no-repeat
                        background-size 100% 100%
                        &:hover
                            background url(../images/arrow3.png) no-repeat
                            background-size 100% 100%
                            color #ffffff

</style>
